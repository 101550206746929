<template>
    <div>
        <div class="post-img-block">
            <div class="post-img" :style="{ backgroundImage: 'url(' + require('@/assets/' + img) + ')' }"></div>
            <h1> {{ title }} </h1>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PostImg",
        props: {
            title: String,
            img: String
        }
    }
</script>

<style scoped>
    .post-img-block {
        width: 100%;
        height: 360px;
        margin-top: 2rem;
        padding: 36px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .post-img{
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        filter: brightness(.6);
        position: absolute;
        z-index: 9;
    }
    .post-img-block h1 {
        font-size: 36px;
        font-weight: 600;
        letter-spacing: 5px;
        color: #fff;
        text-align: center;
        position: relative;
        z-index: 10;
    }
</style>