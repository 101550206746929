<template>
    <div class="popular-title">
        <h3>熱門文章</h3>
        <button v-on:click="addPostLikes" style="display: none;">Add Post Likes</button>
    </div>
    <div class="popular-posts-row">
        <PopularSinglePost :popular_posts="popular_posts" />
    </div>
</template>

<script>
    import PopularSinglePost from './PopularSinglePost'
    import { db } from '../db'
    import { collection, getDocs, limit, orderBy, query, setDoc, doc } from "firebase/firestore";

    async function getPosts(thenFunction) {
        const post_query = query(
            collection(db, "post_words_coll"),
            orderBy("post_likes", "desc"),
            limit(4)
        );

        const query_snapshot = await getDocs(post_query);
        query_snapshot.forEach((doc) => {
            if(doc.exists()){
                thenFunction(doc.data())
            }
        });
    }

    export default {
        name: 'PopularPosts',
        components: {
            PopularSinglePost,
        },
        data() {
            return {
                popular_posts: [],
            }
        },
        created() {
            getPosts(popular_posts => {
                this.popular_posts.push({
                    post_id: popular_posts.post_id,
                    post_title: popular_posts.post_title.substring(0, 15) + "...",
                    post_content: popular_posts.post_content.para_1[1].substring(0, 25) + "...",
                    post_img: popular_posts.post_img,
                    post_date: popular_posts.post_date
                })
            })
        },
        methods: {
            addPostLikes() {
                for (let count = 1; count < 12; count++) {
                    const docRef = doc(db, 'post_words_coll', 'post_words_' + (count))
                    setDoc(docRef, { post_likes: 0 }, { merge: true });
                }
            }
        }
    }
</script>

<style scoped>
    .popular-title{
        width: 80%;
        margin: auto;
        margin-top: 4rem;
        border-bottom: 2px solid #d7d7d7;
    }
    .popular-title h3{
        width: 18%;
        padding: 5px 10px;
        border-bottom: 4px solid #000;
        font-size: 28px;
        transform: translateY(2px);
    }
    .popular-posts-row{
        width: 80%;
        height: fit-content;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }
</style>