<template>
    <div v-if="!hasType || search_title">
        <div class="search-title-block">
            <div v-if="search_title === ''" class="search-title">
                <h1 v-if="search_type === 'category'" style="color: #5e5596;">選擇類別</h1>
                <h1 v-else-if="search_type === 'tag'" style="color: #d8c194">選擇詞條</h1>
            </div>
            <div v-else class="search-title">
                <h1 v-if="search_type === 'category'" style="color: #5e5596;">依類別搜尋: {{ this.search_title }}</h1>
                <h1 v-else-if="search_type === 'tag'" style="color: #d8c194">依詞條搜尋: {{ this.search_title }}</h1>
            </div>
            <div class="search-tags-block">
                <div v-if="search_type === 'category'" class="search-cat">
                    <router-link v-for="tag in search_tags" :key="tag.name" class="tag-btn post-cat" :to="'/search/category/' + tag.id" :style="{backgroundColor: tag.color, textDecoration: 'none'}"> {{ tag.name }} </router-link>
                </div>
                <div v-else-if="search_type === 'tag'" class="search-tag">
                    <router-link v-for="tag in search_tags" :key="tag.tag_id" class="tag-btn post-tags" :to="'/search/tag/' + tag.tag_id" style="text-decoration: none"> {{ "# " + tag.tag_name }} </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { db } from '../db'
    import { collection, getDocs, query } from "firebase/firestore"; 

    async function getPostTags(thenFunction) {
        const post_tag_query = query(
            collection(db, "post_tags_coll"),
        );

        const query_snapshot = await getDocs(post_tag_query);
        query_snapshot.forEach((doc) => {
            if(doc.exists()){
                thenFunction(doc.data())
            }
        });
    }

    async function getCatTags(thenFunction) {
        const search_tags_query = query(
            collection(db, "post_cat_coll"),
        );

        const query_snapshot = await getDocs(search_tags_query);
        query_snapshot.forEach((doc) => {
            if(doc.exists()){
                thenFunction(doc.data())
            }
        });
    }

    export default {
        name: 'SearchPageCat',
        data() {
            return {
                hasType: false,
                search_type: "",
                search_tags: [],
                search_title: ""
            }
        },
        created() {
            this.search_type = this.$route.params.type;
            if(this.$route.params.searchType){
                this.hasType = true;
            }
            if(this.search_type === "category"){
                getCatTags(search_tags => {
                    this.search_tags.push(search_tags)
                    if(this.hasType && search_tags.id === this.$route.params.searchType){
                        this.hasType = true
                        this.search_title = search_tags.name
                    }
                })
            }
            else if(this.search_type === "tag"){
                getPostTags(search_tags => {
                    this.search_tags.push(search_tags)
                    if(this.hasType && search_tags.tag_id === this.$route.params.searchType){
                        this.search_title = search_tags.tag_name
                    }
                })
            }
        },
    }
</script>

<style scoped>
    .search-title-block{
        width: 100%;
        height: fit-content;
        min-height: 250px;
        background-color: #fff;
        margin: 30px 0;
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .search-title{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .search-title h1{
        font-size: 40px;
        letter-spacing: 5px;
    }
    .search-tags-block{
        width: 64%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search-cat{
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }
    .search-tag{
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .tag-btn{
        width: fit-content;
        outline: none;
        border: none;
        border-radius: 8px;
        font-size: 16px;
        transition: .25s;
    }
    .post-cat{       
        height: 40px; 
        color: #fff;
        padding: 5px 15px;
    }
    .post-cat:hover{
        cursor: pointer;
        filter: brightness(.8);
    }
    .post-tags{
        height: fit-content;
        padding: 10px 20px;
        border-radius: 6px;
        background-color: #9de0e0;
        color: #156d6d;
        text-align: center;
        margin: 5px;
    }
    .post-tags:hover{
        cursor: pointer;
        background-color: #156d6d;
        color: #fff;
    }
</style>