<template>
    <div class="footer-block">
        <InfoSection class="footer-section" />
        <ContentSection v-for="content in content_list" :key="content.title" :title="content.title" :list="content.list" />
        <QuoteSection class="footer-section" />
    </div>
</template>

<script>
    import InfoSection from './InfoSection.vue'
    import ContentSection from './ContentSection.vue'
    import QuoteSection from './QuoteSection.vue'

    export default {
        name: 'NavFooter',
        components: {
            InfoSection,
            ContentSection,
            QuoteSection
        },
        data() {
            return {
                content_list: ''
            }
        },
        created() {
            this.content_list = [
                {
                    title: '文章類別',
                    list: [
                        {
                            title: '網路媒體',
                            link: '/search/category/social_media'
                        },
                        {
                            title: '理律講堂',
                            link: '/search/category/law-studio'
                        },
                        {
                            title: '議題探討',
                            link: '/search/category/issue_discuss'
                        },
                        {
                            title: '更多類別',
                            link: '/search/category'
                        }
                    ],
                },
                {
                    title: '文章詞條',
                    list: [
                        {
                            title: '烏俄戰爭',
                            link: '/search/tag/war'
                        },
                        {
                            title: '動物展演',
                            link: '/search/tag/animal_exhibition'
                        },
                        {
                            title: '順法抗爭',
                            link: '/search/tag/lawful_protest'
                        },
                        {
                            title: '更多詞條',
                            link: '/search/tag'
                        }
                    ]
                }
            ]
        }
    }
</script>

<style scoped>
    .footer-block{
        width: 100%;
        height: 320px;
        background-color: #9de0e0;
        margin-top: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer-section{
        width: 22%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
</style>