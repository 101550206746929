<template>
    <div class="all-posts-block">
        <div class="all-posts-title">
            <h1>所有文章</h1>
        </div>
        <router-link :to="`/content/${post.post_id}`" style="text-decoration: none; color: inherit;" class="all-posts" v-for="post in all_posts" :key="post.id">
            <div class="all-posts-img" :style="{ backgroundImage: 'url(' + require('@/assets/' + post.post_img) + ')' }"></div>
            <h3><span> {{ post.post_title }} </span></h3>
            <p class="all-posts-content"> {{ post.post_content }} </p>
            <p class="all-posts-date"> {{ post.post_date }} </p>
        </router-link>
    </div>
</template>

<script>
    import { db } from '../db'
    import { query, collection, orderBy, getDocs } from "firebase/firestore";
    
    async function getPosts(thenFunction) {
        const post_query = query(
            collection(db, "post_words_coll"),
            orderBy("post_date", "desc")
        );
        const query_snapshot = await getDocs(post_query);
        query_snapshot.forEach((doc) => {
            if(doc.exists()){
                thenFunction(doc.data())
            }
        });
    }

    export default {
        name: 'AllPosts',
        data() {
            return {
                all_posts: [],
            }
        },
        created() {
            getPosts(all_posts => {
                this.all_posts.push({
                    post_id: all_posts.post_id,
                    post_title: all_posts.post_title.substring(0, 10) + "...",
                    post_content: all_posts.post_content.para_1[1].substring(0, 10) + "...",
                    post_img: all_posts.post_img,
                    post_date: all_posts.post_date
                })
            })
        },
    }
</script>

<style scoped>
    .all-posts-block{
        width: 90%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin: auto
    }
    .all-posts-title{
        width: 100%;
        margin-bottom: 40px;
        font-size: 20px;
        text-align: center;
    }
    .all-posts{
        width: 30%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 10px 0;
        border-radius: 6px;
    }
    .all-posts:hover{
        cursor: pointer;
    }
    .all-posts-img{
        width: 95%;
        height: 200px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: .2s;
    }
    .all-posts:hover .all-posts-img{
        filter: brightness(.8);
    }
    .all-posts h3{
        font-size: 24px;
        font-weight: 600;
        margin: 10px 0;
    }
    .all-posts span{
        transition: .2s;
    }
    .all-posts:hover span{
        -webkit-box-shadow: inset 0px -18px 0px -6px #51afaf83;
        box-shadow: inset 0px -18px 0px -6px #51afaf83;
    }
    .all-posts-content{
        font-size: 18px;
        color: #8c8c8c;
        margin: 10px 0;
    }
    .all-posts-date{
        font-size: 16px;
        font-family: 'Nunito', sans-serif;
        margin: 10px 0;
    }
</style>