<template>
    <router-link v-for="tag in tags" :key="tag.tag_id" :to="tag.tag_route" :style="{ backgroundColor: tag.tag_bgcolor, color: tag.tag_color, textDecoration: 'none' }" class="tag-btn">
        {{ tag.tag_name }}
    </router-link>
</template>

<script>
    export default {
        name: 'SingleType',
        props:{
            tags: Array,
        }
    }
</script>

<style scoped>
    .tag-btn{
        width: fit-content;
        height: 40px;
        padding: 5px 15px;
        outline: none;
        border: none;
        border-radius: 8px;
        margin-right: 15px;
        font-size: 16px;
        transition: .25s;
        opacity: 0;
        transform: translateX(10px);
    }
    .tag-btn:hover{
        cursor: pointer;
        filter: brightness(.8);
    }
    .show-tags{
        animation: fadeIn .3s ease-in-out forwards;
    }
    @keyframes fadeIn{
        0%{
            transform: translateX(10px);
            opacity: 0;
        }
        100%{
            transform: translateX(0);
            opacity: 1;
        }
    }
    .hide-tags{
        animation: fadeOut .3s ease-in-out forwards;
    }
    @keyframes fadeOut{
        0%{
            transform: translateX(0);
            opacity: 1;
        }
        100%{
            transform: translateX(10px);
            opacity: 0;
        }
    }
</style>