<template>
    <div class="info-section">
        <h1>歪踢法律練功坊 <br /> YT Law Studio</h1>
        <div>
            <p><b>撰稿者</b>: 廖宥婷 Christine Liao</p>
            <p><b>Email</b>: christine@isper.net</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'InfoSection'
    }
</script>

<style scoped>
    .info-section h1{
        font-size: 30px;
        letter-spacing: 3px;
        color: #156d6d;
    }
    .info-section div{
        width: fit-content;
        height: fit-content;
    }
    .info-section p{
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 2.4;
    }
</style>