<template>
    <div class="latest-title">
        <h3>最新文章</h3>
    </div>
    <div class="latest-posts">
        <LatestSinglePost :latest_posts="latest_posts" />
    </div>
</template>

<script>
    import LatestSinglePost from './LatestSinglePost';
    import { db } from '../db'
    import { collection, getDocs, limit, query, orderBy } from "firebase/firestore";

    async function getPosts(thenFunction) {
        const post_query = query(
            collection(db, "post_words_coll"),
            orderBy("post_date", "desc"),
            limit(5)
        );

        const query_snapshot = await getDocs(post_query);
        query_snapshot.forEach((doc) => {
            if(doc.exists()){
                thenFunction(doc.data())
            }
        });
    }

    export default {
        name: 'LatestPosts',
        components: {
            LatestSinglePost,
        },
        data() {
            return {
                latest_posts: [],
                temp: []
            }
        },
        created() {
            let post_cnt = 1;
            getPosts(latest_posts => {
                if (post_cnt == 1) {
                    this.latest_posts.push({
                        post_id: 1,
                        id: latest_posts.post_id,
                        post_type: "large",
                        post_title: latest_posts.post_title,
                        post_content: latest_posts.post_content.para_1[1].substring(0, 40) + "...",
                        post_img: latest_posts.post_img,
                        post_date: latest_posts.post_date
                    })
                } else if (post_cnt == 2) {
                    this.latest_posts.push({
                        post_id: 2,
                        post_type: "small",
                        post_content: [
                            {
                                id: latest_posts.post_id,
                                title: latest_posts.post_title,
                                date: latest_posts.post_date,
                                img: latest_posts.post_img
                            },
                        ],
                    })
                } else if (post_cnt == 3) {
                    this.latest_posts[1].post_content.push({
                        id: latest_posts.post_id,
                        title: latest_posts.post_title,
                        date: latest_posts.post_date,
                        img: latest_posts.post_img
                    })
                } else if (post_cnt == 4) {
                    this.latest_posts.push({
                        post_id: 4,
                        post_type: "small",
                        post_content: [
                            {
                                id: latest_posts.post_id,
                                title: latest_posts.post_title,
                                date: latest_posts.post_date,
                                img: latest_posts.post_img
                            },
                        ],
                    })
                } else if (post_cnt == 5) {
                    this.latest_posts[2].post_content.push({
                        id: latest_posts.post_id,
                        title: latest_posts.post_title,
                        date: latest_posts.post_date,
                        img: latest_posts.post_img
                    })
                }
                post_cnt++;
            })
        }
    }
</script>

<style scoped>
    .latest-title{
        width: 80%;
        margin: auto;
        margin-top: 4rem;
        border-bottom: 2px solid #d7d7d7;
    }
    .latest-title h3{
        width: 18%;
        padding: 5px 10px;
        border-bottom: 4px solid #000;
        font-size: 28px;
        transform: translateY(2px);
    }
    .latest-posts{
        width: 80%;
        height: 400px;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>