import { initializeApp } from "firebase/app";

const firebaseConfig = {

    apiKey: "AIzaSyCHP8NO_v3XFjolo9woPGDdCLjmTrJH2jk",
  
    authDomain: "law-studio-39b69.firebaseapp.com",
  
    projectId: "law-studio-39b69",
  
    storageBucket: "law-studio-39b69.appspot.com",
  
    messagingSenderId: "557537340829",
  
    appId: "1:557537340829:web:f06effedc4117181d31374",
  
    measurementId: "G-DXP8LXC5GD"
  
};  

export const app = initializeApp(firebaseConfig);