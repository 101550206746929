<template>
    <div>
        <PostImg :title="post_title" :img="post_img" />
        <div class="post-content-block">
            <LikeTitle :like_title="like_title" />
            <div class="post-content">
                <CatTags :post_cat="post_cat" :post_date="post_date" :post_likes="post_likes" :doc_id="doc_id" />
                <ContentWords :content_words="content_words" />
                <ContentTags :content_tags="content_tags" />
            </div>
        </div>
    </div>
</template>

<script>
    import PostImg from '../components/PostImg'
    import LikeTitle from '../components/LikeTitle'
    import CatTags from '../components/CatTags'
    import ContentWords from '../components/ContentWords'
    import ContentTags from '../components/ContentTags'
    import { db } from '../db'
    import { collection, getDocs, query, where } from "firebase/firestore"; 

    export default {
        name: "PostContent",
        components: {
            PostImg,
            LikeTitle,
            CatTags,
            ContentWords,
            ContentTags,
        },
        data() {
            return {
                post_title: "",
                post_img: "",
                post_date: "",
                like_title: [],
                post_cat: [],
                post_likes: 0,
                content_words: [],
                content_tags: [],
                doc_id: ""
            }
        },
        methods: {
            shuffleArray(array) {
                for (let i = array.length - 1; i > 0; i--) {
                    let j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
            },
            async postData(){
                const postId = this.$route.params.postId
                const post_ref = collection(db, "post_words_coll")
                const post_query = query(post_ref, where("post_id", "==", postId))
                const post_words = await getDocs(post_query)

                post_words.forEach((doc) => {
                    if(doc.exists()){
                        this.doc_id = doc.id
                        this.post_title = doc.data()['post_title']
                        this.post_img = doc.data()['post_img']
                        this.post_date = doc.data()['post_date']
                        this.post_cat = doc.data()['post_cat']
                        this.post_likes = doc.data()['post_likes']
                        this.content_words = doc.data()['post_content']
                        this.content_tags = doc.data()['post_tag']
                    }
                })
            },
            async likeTitle(){
                const postId = this.$route.params.postId
                const post_ref = collection(db, "post_words_coll")
                const post_query = query(post_ref, where("post_id", "!=", postId))
                const post_words = await getDocs(post_query)

                post_words.forEach((doc) => {
                    if(doc.exists()){
                        this.like_title.push({
                            id: doc.data()['post_id'],
                            title: doc.data()['post_title'].substring(0, 8) + "..."
                        })
                        this.shuffleArray(this.like_title);
                        this.like_title = this.like_title.slice(0, 4)
                    }
                })
            }
        },
        created() {
            this.postData()
            this.likeTitle();
        },
    }
</script>

<style scoped>
    .post-content-block{
        width: 100%;
        height: max-content;
        margin-top: 3rem;
        padding: 30px;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }
    .post-content{
        width: 70%;
        height: fit-content;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
    }
</style>