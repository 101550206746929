<template>
    <div class="latest-post-col" v-for="post in latest_posts" :key="post.id">
        <router-link :to="`/content/${post.id}`" style="text-decoration: none; color: inherit;" v-if="post.post_type == 'large'" class="large-post post">
            <div class="large-post-img" :style="{ backgroundImage: 'url(' + require('@/assets/' + post.post_img) + ')' }"></div>
            <h3><span> {{ post.post_title }} </span></h3>
            <p class="large-post-content"> {{ post.post_content }} </p>
            <p class="large-post-date"> {{ post.post_date }} </p>
        </router-link>
        <router-link :to="`/content/${content.id}`" style="text-decoration: none; color: inherit;" v-else class="small-post post" v-for="content in post.post_content" :key="content.id">
            <div class="small-post-img" :style="{ backgroundImage: 'url(' + require('@/assets/' + content.img) + ')' }"></div>
            <div class="small-post-words">
                <h3><span> {{ content.title }} </span></h3>
                <p class="small-post-date"> {{ content.date }} </p>
            </div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'LatestSinglePost',
        props: {
            latest_posts: Array,
        },
    }
</script>

<style scoped>
    .post{
        width: 100%;
        transition: .2s;
        border-radius: 6px;
        outline: none;
    }
    .post span{
        transition: .2s;
    }
    .post:hover{
        cursor: pointer;
        filter: brightness(.8);
    }
    .post:hover span{
        -webkit-box-shadow: inset 0px -18px 0px -6px #51afaf83;
        box-shadow: inset 0px -18px 0px -6px #51afaf83;
    }
    .latest-post-col{
        width: 30%;
        height: 100%;
    }
    .large-post{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .large-post-img{
        width: 100%;
        height: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .large-post h3{
        font-size: 24px;
        font-weight: 600;
    }
    .large-post-content{
        font-size: 18px;
        color: #8c8c8c;
    }
    .large-post-date{
        font-size: 16px;
        font-family: 'Nunito', sans-serif;
    }
    .small-post{
        height: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .small-post-img{
        width: 40%;
        aspect-ratio: 1 / 1;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .small-post-words{
        width: 50%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }    
    .small-post-date{
        font-size: 16px;
        font-family: 'Nunito', sans-serif;
        color: #8c8c8c;
    }
</style>