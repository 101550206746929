<template>
    <div class="tags-row">
        <SingleType :tags="tags" />
        <button class="search-btn" @click="toggleTags()"> {{ search_text }} </button>
    </div>
</template>

<script>
    import SingleType from './SingleType'

    export default {
        name: 'SearchTags',
        props:{
            search_text: String,
        },
        components:{
            SingleType,
        },
        data() {
            return {
                tags: [],
            }
        },
        created() {
            this.tags = [
                {
                    tag_id: 1,
                    tag_name: "依類別搜尋",
                    tag_bgcolor: "#5e5596",
                    tag_color: "#d8c194",
                    tag_route: "/search/category",
                },
                {
                    tag_id: 2,
                    tag_name: "依詞條搜尋",
                    tag_bgcolor: "#d8c194",
                    tag_color: "#5e5596",
                    tag_route: "/search/tag",
                }
            ] 
        },
        methods: {
            toggleTags() {
                document.querySelectorAll('.tag-btn').forEach(function(el) {
                    if(el.classList.contains('show-tags')){
                        el.classList.remove('show-tags');
                        el.classList.add('hide-tags');
                    }else{
                        el.classList.remove('hide-tags');
                        el.classList.add('show-tags');
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .tags-row{
        height: 100%;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search-btn{
        width: fit-content;
        height: 45px;
        padding: 5px 20px;
        outline: none;
        background: none;
        border: 2px solid #81c5c5;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        color: #81c5c5;
        transition: .25s;
    }
    .search-btn:hover{
        cursor: pointer;
        background-color: #81c5c5;
        color: #fff;
    }
</style>