<template>
    <div class="content-tags">
        <p v-for="tag in content_tags" :key="tag" class="post-tags"> {{ "# " + tag}} </p>
    </div>
</template>

<script>
    export default {
        name: "ContentTags",
        props: {
            content_tags: Array,
        },
    }
</script>

<style scoped>
    .content-tags{
        width: 100%;
        height: fit-content;
        display: flex;;
        align-items: center;
        margin-top: 32px;
    }
    .post-tags{
        width: fit-content;
        height: fit-content;
        padding: 10px 20px;
        border-radius: 6px;
        background-color: #9de0e0;
        font-size: 16px;
        color: #156d6d;
        text-align: center;
        margin-right: 15px;
    }
</style>