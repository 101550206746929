<template>
    <div class="content-words">
        <div v-for="words in contentWords" :key="words[0]">
            <h1> {{ words[0] }} </h1>
            <p v-for="i in words.length - 1" :key="i"> {{ words[i] }} </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContentTags",
        props: {
            content_words: Object
        },
        computed: {
            contentWords(){
                const obj = [];
                for(let i = 0; i < Object.keys(this.content_words).length; i++){
                    var q = "para_" + (i + 1)
                    obj.push(this.content_words[q])
                }
                return obj
            }
        }
    }
</script>

<style scoped>
    .content-words{
        width: 100%;
        height: fit-content;
        font-size: 18px;
        margin-top: 30px;
    }
    .content-words p{
        letter-spacing: 2px;
        line-height: 2;
        color: #757575;
        margin: 18px 0;
    }
    .content-words h1{
        color: #156d6d;
        font-size: 28px;
        line-height: 3;
    }
</style>