<template>
    <div>
        <div class="signin-block" id="signin">
            <h1 class="signin-title">登入帳號</h1>
            <!-- <form action="" class="signin-form">
                <input type="text" class="signin-input" id="username" name="username" placeholder="帳號">
                <input type="text" class="signin-input" id="password" name="password" placeholder="密碼">
                <button class="signin-btn login-btn" type="submit">登入</button>
            </form> -->
            <button class="signin-btn google-btn" id="google">Google</button>
            <button class="signin-btn google-btn" id="logout">登出</button>
        </div>
    </div>
</template>

<script>
    import { GoogleAuthProvider, signInWithRedirect, getRedirectResult, onAuthStateChanged, signOut } from 'firebase/auth'
    import { auth } from '../auth.js'
    import { app } from '../conn.js';
    
    const provider = new GoogleAuthProvider(app);
    

    // const monitorAuthState = async () => {
    //     onAuthStateChanged(auth, user => {
    //         if(user){
    //             console.log("user: " + user.uid);
    //             logIn()
    //         }
    //         else{
    //             console.log("user: null");
    //         }
    //     })
    // }

    export default {
        name: 'SignIn',
        mounted() {
            var navigate = this.$router;
            document.getElementById('google').addEventListener('click', () => {
                signInWithRedirect(auth, provider);
                getRedirectResult(auth).then((result) => {
                    // This gives you a Google Access Token. You can use it to access Google APIs.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    console.log("token: " + token);
                    // The signed-in user info.
                    const user = result.user;
                    console.log("user: " + user);
                });
                // .catch((error) => {
                //     // Handle Errors here.
                //     const errorCode = error.code;
                //     const errorMessage = error.message;
                //     // The email of the user's account used.
                //     const email = error.email;
                //     // The AuthCredential type that was used.
                //     const credential = GoogleAuthProvider.credentialFromError(error);
                //     // ...
                // });
            });
            document.getElementById('logout').addEventListener('click', () => {
                signOut(auth).then(() => {
                    console.log("logout");
                });
            });
            const monitorAuthState = async () => {
                onAuthStateChanged(auth, user => {
                    if(user && (user.uid == "VTs7cGINy8T5U45NVFnDnd7NdXS2" || user.uid == "gueNkRXlemYz8MyV0fitRnfUxV72")){
                        console.log("user: " + user.uid);
                        navigate.push('/edit');
                    }
                    else if(user && (user.uid !== "VTs7cGINy8T5U45NVFnDnd7NdXS2" || user.uid !== "gueNkRXlemYz8MyV0fitRnfUxV72")){
                        alert("You are not authorized to view this page");
                        navigate.push('/');
                    }
                })
            }
            monitorAuthState()
        },
    }
</script>

<style scoped>
    .signin-block {
        width: 360px;
        height: fit-content;
        display: flex;
        background: #fff;
        border-radius: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        padding: 40px 30px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    .signin-title{
        font-size: 36px;
        letter-spacing: 3px;        
        -webkit-box-shadow: inset 0px -25px 0px -6px #51afaf83;
        box-shadow: inset 0px -25px 0px -6px #51afaf83;
        margin-bottom: 30px;
    }
    .signin-form{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .signin-input{
        width: 90%;
        height: 40px;
        background-color: #e4e7ef;
        border: none;
        outline: none;
        border-radius: 20px;
        padding: 20px;
        font-size: 16px;
        margin: 10px 0;
        transition: .25s;
    }
    .signin-input:focus{
        background-color: #d2d6df;
    }
    .signin-btn{
        width: 90%;
        height: 40px;
        border: none;
        outline: none;
        border-radius: 20px;
        color: #fff;
        font-size: 16px;
        transition: .25s;
        cursor: pointer;
    }
    .login-btn{
        margin: 30px 0 20px;
        background-color: #81c5c5;
    }
    .login-btn:hover{
        background-color: #51afaf;
    }
    .google-btn{
        margin: 30px 0;
        background-color: #DB4437;
    }
    .google-btn:hover{
        background-color: #c53b2f;
    }
</style>