<template>
    <div class="quote-section">
        <p>法律是<span>顯露的道德</span>， <br /> 道德是<span>隱藏的法律</span>。</p>
        <small>© 2022 lawstudio.tw. All rights reserved. <br /> Made with 💜 for education </small>
    </div>
</template>

<script>
    export default {
        name: 'QuoteSection'
    }
</script>

<style scoped>
    .quote-section{
        text-align: right;
    }
    .quote-section p{
        font-size: 20px;
        letter-spacing: 2px;
        border-right: 3px solid #aaa;
        padding-right: 20px;
    }
    .quote-section span{
        color: #156d6d;
        font-weight: 600;
    }
</style>