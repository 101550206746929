<template>
    <router-link to="/allPosts" class="all-posts"><span>所有文章一次看 >></span></router-link>
</template>

<script>

</script>

<style scoped>
    .all-posts{
        width: 100%;
        height: fit-content;
        text-decoration: none;
        outline: none;
        font-size: 40px;
        font-weight: 600;
        margin: 80px auto;
        letter-spacing: 5px;
        background-color: #f5f5f5;
        color: #156d6d;
        text-align: center;
        display: inline-block;
    }
    .all-posts span{
        transition: .2s;
    }
    .all-posts span:hover{
        cursor: pointer;
        -webkit-box-shadow: inset 0px -18px 0px -6px #51afaf83;
        box-shadow: inset 0px -18px 0px -6px #51afaf83;
        letter-spacing: 8px;
    }
    .post-tags{
        width: fit-content;
        min-width: 16%;
        height: fit-content;
        padding: 10px 20px;
        border-radius: 6px;
        background-color: #9de0e0;
        font-size: 20px;
        color: #156d6d;
        text-align: center;
    }
</style>