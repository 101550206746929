<template>
    <div>
        <div class="content-form">
            <form action="">
                <div class="form-section">
                    <h1>1. <span>類別</span></h1>
                    <div v-for="tag in cat_tags" :key="tag.id" class="cat-input">
                        <input type="checkbox" :id="tag.id" :name="tag.id" :value="tag.name" v-model="checked_cat_tags">
                        <label :for="tag.id" class="tag-btn post-cat" :style="{backgroundColor: tag.color}">{{ tag.name }}</label>
                    </div>
                </div>
                <div class="form-section">
                    <h1>2. <span>標題</span></h1>
                    <input type="text" id="title" name="title" placeholder="輸入標題" v-model="title">
                </div>
                <div class="form-section">
                    <h1>3. <span>時間</span></h1>
                    <input type="text" id="time" name="time" placeholder="輸入時間" v-model="time">
                </div>
                <div class="form-section" id="content-section">
                    <h1>4. <span>內容</span></h1>
                    <label for="subtitle-1" class="content-label">副標題</label>
                    <input type="text" id="subtitle-1" name="subtitle-1" placeholder="輸入副標題">
                    <label for="para1-1" class="content-label">副段</label>
                    <textarea id="para1-1" rows="3"></textarea>
                </div>
                <div class="content-btn-row">
                    <button class="content-btn" type="button" @click="addSubPara()">增加副段</button>
                    <button class="content-btn" type="button" @click="addWholePara()">增加段落</button>
                </div>
                <div class="form-section">
                    <h1>5. <span>詞條</span></h1>
                    <div v-for="tag in post_tags" :key="tag.tag_id" class="cat-input">
                        <input type="checkbox" :id="tag.tag_id" :name="tag.tag_id" :value="tag.tag_name" v-model="checked_post_tags">
                        <label :for="tag.tag_id" class="tag-btn post-tags">{{ tag.tag_name }}</label>
                    </div>
                </div>
                <div class="form-section">
                    <h1>6. <span>圖片</span></h1>
                    <div v-for="ind in 14" :key="ind" class="img-input" :style="{ backgroundImage: 'url(' + require('@/assets/content' + ind + '.jpg') }"></div>
                    <input type="text" id="img" name="img" placeholder="輸入圖片" v-model="img">
                </div>
                <button class="content-btn" type="button" style="margin-top: 50px;" @click="writePostDoc()">完成編輯</button>
            </form>
        </div>
    </div>
</template>

<script>
    import { signOut } from 'firebase/auth'
    import { auth } from '../auth.js'
    import { db } from '../db'
    import { collection, getDocs, query, doc, setDoc } from "firebase/firestore"; 

    var cur_para = 1;
    var cur_subpara = [1];
    var count;

    async function getPostTags(thenFunction) {
        const post_tag_query = query(
            collection(db, "post_tags_coll"),
        );

        const query_snapshot = await getDocs(post_tag_query);
        query_snapshot.forEach((doc) => {
            if(doc.exists()){
                thenFunction(doc.data())
            }
        });
    }

    async function getCatTags(thenFunction) {
        const search_tags_query = query(
            collection(db, "post_cat_coll"),
        );

        const query_snapshot = await getDocs(search_tags_query);
        query_snapshot.forEach((doc) => {
            if(doc.exists()){
                thenFunction(doc.data())
            }
        });
    }

    async function getCount(){
        const querySnapshot = await getDocs(collection(db, "post_words_coll"));
        return querySnapshot.size;
    }

    async function getAndStoreCount() {
        count = await getCount();
    }

    getAndStoreCount();

    export default {
        name: 'EditContent',
        data() {
            return {
                title: '',
                time: '',
                cat_tags: [],
                checked_cat_tags: [],
                post_tags: [],
                checked_post_tags: [],
                img: '',
            }
        },
        created() {
            getCatTags(cat_tags => {
                this.cat_tags.push(cat_tags)
            })
            getPostTags(post_tags => {
                this.post_tags.push(post_tags)
            })
        },
        mounted() {
            const password = prompt("Please enter your password:");
            if(password != "ting0305"){
                this.$router.push('/');
            }
        },
        methods: {
            addWholePara(){
                cur_para += 1;
                cur_subpara.push(1);

                const new_label = document.createElement("label");
                new_label.setAttribute("for", "subtitle-" + cur_para);
                new_label.setAttribute("class", "content-label");
                new_label.innerHTML = "副標題";

                const new_input = document.createElement("input");
                new_input.setAttribute("type", "text");
                new_input.setAttribute("id", "subtitle-" + cur_para);
                new_input.setAttribute("name", "subtitle-" + cur_para);
                new_input.setAttribute("placeholder", "輸入副標題");

                const new_label2 = document.createElement("label");
                new_label2.setAttribute("for", "para" + cur_para + "-" + cur_subpara[cur_para - 1]);
                new_label2.setAttribute("class", "content-label");
                new_label2.innerHTML = "副段";

                const new_textarea = document.createElement("textarea");
                new_textarea.setAttribute("id", "para" + cur_para + "-" + cur_subpara[cur_para - 1]);
                new_textarea.setAttribute("rows", "3");

                document.getElementById("content-section").appendChild(new_label)
                document.getElementById("content-section").appendChild(new_input)
                document.getElementById("content-section").appendChild(new_label2)
                document.getElementById("content-section").appendChild(new_textarea)
            },

            addSubPara(){
                cur_subpara[cur_para - 1] += 1;

                const new_label = document.createElement("label")
                new_label.setAttribute("for", "para" + cur_para + "-" + cur_subpara[cur_para - 1])
                new_label.setAttribute("class", "content-label")
                new_label.innerHTML = "副段"

                const new_textarea = document.createElement("textarea")
                new_textarea.setAttribute("id", "para" + cur_para + "-" + cur_subpara[cur_para - 1])
                new_textarea.setAttribute("rows", "3")

                document.getElementById("content-section").appendChild(new_label)
                document.getElementById("content-section").appendChild(new_textarea)
            },

            userSignOut(){
                signOut(auth).then(() => {
                    this.$router.push('/signin');
                })
            },

            writePostDoc(){
                // formatted_date = new Date().toJSON().slice(0,10).replace(/-/g,'/')
                var id_string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
                    id = "",
                    post_cat_result = [],
                    post_cat_id = [],
                    post_tag_id = [],
                    post_content_result = {};
                
                for(var i = 0; i < 8; i++){
                    id += id_string.charAt(Math.floor(Math.random() * id_string.length));
                }

                for(i = 0; i < this.checked_cat_tags.length; i++){
                    post_cat_result.push(
                        {
                            color: this.cat_tags.find(x => x.name == this.checked_cat_tags[i]).color,
                            name: this.cat_tags.find(x => x.name == this.checked_cat_tags[i]).name,
                        }
                    )
                    post_cat_id.push(this.cat_tags.find(x => x.name == this.checked_cat_tags[i]).id)
                }

                for(i = 0; i < this.checked_post_tags.length; i++){
                    post_tag_id.push(this.post_tags.find(x => x.tag_name == this.checked_post_tags[i]).tag_id)
                }

                for(i = 0; i < cur_para; i++){
                    var post_para = [ document.getElementById("subtitle-" + (i + 1)).value ];
                    for(var j = 0; j < cur_subpara[i]; j++){
                        post_para.push(document.getElementById("para" + (i + 1) + "-" + (j + 1)).value);
                    }
                    post_content_result["para_" + (i + 1)] = post_para;
                }

                const new_post = doc(db, 'post_words_coll', 'post_words_' + (count + 1))
                const doc_data = {
                    post_title: this.title,
                    post_date: this.time,
                    post_img: this.img,
                    post_id: id,
                    post_tag: this.checked_post_tags,
                    post_tag_id: post_tag_id,
                    post_cat: post_cat_result,
                    post_cat_id: post_cat_id,
                    post_likes: 0,
                    post_content: post_content_result,
                }

                setDoc(new_post, doc_data, { merge: true }).then(() => {
                    alert("文章已經成功寫入");
                    this.$router.go()
                })
            }
        }
    }
</script>

<style scoped>
    .content-form{
        width: 100%;
        height: fit-content;
    }
    .content-form form{
        width: 90%;
        height: fit-content;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .form-section{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 50px;
    }
    .form-section h1{
        width: 100%;
        letter-spacing: 2px;
    }
    .form-section span{
        -webkit-box-shadow: inset 0px -24px 0px -6px #51afaf83;
        box-shadow: inset 0px -24px 0px -6px #51afaf83;
    }
    .cat-input{
        margin: 30px 10px;
    }
    input[type=checkbox] {
        margin-right: 8px;
        user-select: none; 
    }
    .img-input {
        width: calc(20% - 20px);
        height: 200px;
        margin: 10px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .tag-btn{
        width: fit-content;
        outline: none;
        border: none;
        font-size: 16px;
        border-radius: 8px;
        transition: .25s;
        padding: 5px 15px;
        display: inline-block;
    }
    .post-cat{       
        height: 40px; 
        color: #fff;
    }
    .post-cat:hover{
        cursor: pointer;
    }
    :deep(input[type=text]){
        width: 100%;
        height: 50px;
        border-radius: 8px;
        border: none;
        outline: none;
        margin-top: 30px;
        padding: 10px 20px;
        font-size: 16px;
        transition: .25s;
    }
    :deep(.content-label){
        width: 100%;
        margin-top: 30px;
        text-align: center;
        font-size: 20px;
        color: #81c5c5;
        font-weight: 600;
    }
    :deep(.form-section) textarea{
        width: 100%;
        height: 200px;
        border-radius: 8px;
        border: none;
        outline: none;
        margin-top: 30px;
        padding: 10px 20px;
        font-size: 16px;
    }
    .content-btn-row{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
    .content-btn{
        width: fit-content;
        height: 45px;
        padding: 5px 20px;
        outline: none;
        background: none;
        margin-inline: 10px;
        border: 2px solid #81c5c5;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        color: #81c5c5;
        transition: .25s;
    }
    .content-btn:hover{
        cursor: pointer;
        background-color: #81c5c5;
        color: #fff;
    }
    .post-tags{
        height: fit-content;
        border-radius: 6px;
        background-color: #9de0e0;
        color: #156d6d;
        text-align: center;
        margin: 5px;
    }
    .post-tags:hover{
        cursor: pointer;
    }
</style>